
import React from 'react'
import Layout from '../components/layout'
import { 
    Container,
    Row,
    Col
  } from 'react-bootstrap'

const PageNotFound = ({data}) => {
  return (
    <>
      <Layout>
        <Container className='pt-5'>
            <Row>
                <Col><h1>404 - This page is not here. Sorry, it may have moved.</h1>
                </Col>
            </Row>
        </Container>
      </Layout>
    </>
  )
}

export default PageNotFound